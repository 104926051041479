
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { CareReceiver } from "@/models/care_receivers";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { pVSuggestTableIcons } from "@/utils/iconBars"

  export default defineComponent({
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed(() => root.$store.state.care_receivers.pagination.totalItems)
      const getItems = async(ctx: BvTableCtxObject) => {
        const queryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-created_at'
        }
        await root.$store.dispatch('care_receivers/load', queryParams)
        return root.$store.state.care_receivers.data
      }

      const columns = ref([
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.firstName",
          title: "Vorname",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.lastName",
          title: "Nachname",
          sortable: true,
          options: {
            hrefFunction: (data: any) => `/care_receivers/${data.item.id}`,
            contentFunction: (data: any) => (data.item.attributes.lastName || data.item.id)
          }
        },
        {
          component: () => import("@/components/shared/table/cells/MultipleIconsCell.vue"),
          label: "1. Pflegebedürftige",
          title: "Icons",
          key: 'attributes',
          options: {
            icons: pVSuggestTableIcons
          }
        },
      ])

      return {
        getItems,
        totalItems,
        columns
      }
    }
  })
