const cRIconBar = [
  {
    key: 'careLevel',
    mappedIcons: [
      {
        value: '1',
        stack: [
          {
            title: 'Pflegegrad 1',
            prefix: 'fas',
            icon: 'thermometer-empty',
            class: 'text-muted fa-2x'
          }
        ]
      },
      {
        value: '2',
        stack: [
          {
            title: 'Pflegegrad 2',
            prefix: 'fas',
            icon: 'thermometer-quarter',
            class: 'text-muted fa-2x'
          }
        ]
      },
      {
        value: '3',
        stack: [
          {
            title: 'Pflegegrad 3',
            prefix: 'fas',
            icon: 'thermometer-half',
            class: 'text-muted fa-2x'
          }
        ]
      },
      {
        value: '4',
        stack: [
          {
            title: 'Pflegegrad 4',
            prefix: 'fas',
            icon: 'thermometer-three-quarters',
            class: 'text-muted fa-2x'
          }
        ]
      },
      {
        value: '5',
        stack: [
          {
            title: 'Pflegegrad 5',
            prefix: 'fas',
            icon: 'thermometer-full',
            class: 'text-muted fa-2x'
          }
        ]
      },
      {
        value: 'kein Pflegegrad',
        stack: [
          {
            title: 'kein Pflegegrad',
            prefix: 'fas',
            icon: 'times',
            class: 'text-muted fa-2x'
          }
        ]
      }
    ]
  },
  {
    key: 'gender',
    mappedIcons: [
      {
        value: 'Mann',
        stack: [
          {
            title: 'männlich',
            prefix: 'fas',
            icon: 'mars',
            class: 'text-muted fa-2x'
          }
        ]
      },
      {
        value: 'Frau',
        stack: [
          {
            title: 'weiblich',
            prefix: 'fas',
            icon: 'venus',
            class: 'text-muted fa-2x'
          }
        ]
      }
    ]
  },
  {
    key: 'roomMate',
    mappedIcons: [
      {
        value: 'alleinlebend',
        stack: [
          {
            title: 'Wohnsituation: alleinlebend',
            prefix: 'fas',
            icon: 'user',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'Wohngemeinschaft',
        stack: [
          {
            title: 'Wohnsituation: In Wohngemeinschaft',
            prefix: 'fas',
            icon: 'user-plus',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'Ehepartner',
        stack: [
          {
            title: 'Wohnsituation: Mit Ehepartner',
            prefix: 'fas',
            icon: 'user-plus',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'Angehöriger',
        stack: [
          {
            title: 'Wohnsituation: Mit Angehörigem',
            prefix: 'fas',
            icon: 'user-plus',
            class: 'text-muted'
          }
        ]
      }
    ]
  },
  {
    key: 'internet',
    mappedIcons: [
      {
        value: 'vorhanden',
        stack: [
          {
            title: 'Internet vorhanden',
            prefix: 'fas',
            icon: 'wifi',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'kann eingerichtet werden',
        stack: [
          {
            title: 'Internet kann eingerichtet werden',
            prefix: 'fas',
            icon: 'wifi',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'Nein, nicht möglich',
        stack: [
          {
            title: 'kein Internet',
            prefix: 'fas',
            icon: 'wifi-slash',
            class: 'text-muted'
          }
        ]
      }
    ]
  },
  {
    key: 'walk',
    mappedIcons: [
      {
        value: 'uneingeschränkt',
        stack: [
          {
            title: 'Gehen: uneingeschränkt',
            prefix: 'fas',
            icon: 'walking',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'teilweise eingeschränkt',
        stack: [
          {
            title: 'Gehen: teilweise eingeschränkt',
            prefix: 'fas',
            icon: 'walking',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'eingeschränkt',
        stack: [
          {
            title: 'Gehen: eingeschränkt',
            prefix: 'fas',
            icon: 'walking',
            class: 'text-muted'
          },
          {
            title: 'Gehen: eingeschränkt',
            prefix: 'far',
            icon: 'ban',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'nicht möglich',
        stack: [
          {
            title: 'Gehen: nicht möglich',
            prefix: 'fas',
            icon: 'walking',
            class: 'text-muted'
          },
          {
            title: 'Gehen: nicht möglich',
            prefix: 'far',
            icon: 'ban',
            class: 'text-muted'
          }
        ]
      }
    ]
  },
  {
    key: 'ctResidenceType',
    mappedIcons: [
      {
        value: 'Einfamilienhaus',
        stack: [
          {
            title: 'Einfamilienhaus',
            prefix: 'far',
            icon: 'home-alt',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'Wohnung',
        stack: [
          {
            title: 'Wohnung',
            prefix: 'far',
            icon: 'home-alt',
            class: 'text-muted'
          },
          {
            title: 'Wohnung',
            prefix: 'far',
            icon: 'ban',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'Mehrfamilienhaus',
        stack: [
          {
            title: 'Mehrfamilienhaus',
            prefix: 'far',
            icon: 'home-alt',
            class: 'text-muted'
          },
          {
            title: 'Mehrfamilienhaus',
            prefix: 'far',
            icon: 'ban',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'Sonstiges',
        stack: [
          {
            title: 'Sonstiges',
            prefix: 'far',
            icon: 'home-alt',
            class: 'text-muted'
          },
          {
            title: 'Sonstiges',
            prefix: 'far',
            icon: 'ban',
            class: 'text-muted'
          }
        ]
      }
    ]
  },
  {
    key: 'ctResidence',
    mappedIcons: [
      {
        value: 'Dorf',
        stack: [
          {
            title: 'Wohnort: Dorf',
            prefix: 'fas',
            icon: 'city',
            class: 'text-muted'
          },
          {
            title: 'Wohnort: Dorf',
            prefix: 'far',
            icon: 'ban',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'Kleinstadt',
        stack: [
          {
            title: 'Wohnort: Kleinstadt',
            prefix: 'fas',
            icon: 'city',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'Großstadt',
        stack: [
          {
            title: 'Wohnort: Großstadt',
            prefix: 'fas',
            icon: 'city',
            class: 'text-muted'
          }
        ]
      }
    ]
  },
  {
    key: 'challengingBehaviour',
    mappedIcons: [
      {
        value: 'Ja',
        stack: [
          {
            title: 'herausfordernde Verhaltensweisen',
            prefix: 'far',
            icon: 'angry',
            class: 'text-muted fa-2x'
          }
        ]
      },
      {
        value: 'Nein',
        stack: [
          {
            title: 'keine herausfordernde Verhaltensweisen',
            prefix: 'far',
            icon: 'smile',
            class: 'text-muted fa-2x'
          }
        ]
      }
    ]
  }
]

export const careReceiverIconBar = cRIconBar

export const pVSuggestTableIcons = [
  {
    key: 'crCount',
    mappedIcons: [
      {
        value: '1',
        stack: [
          {
            title: 'Pflegebedürftige im Haushalt',
            prefix: 'far',
            icon: 'circle',
            class: 'text-muted fa-2x'
          },
          {
            class: 'text-muted',
            title: '1',
          },
        ]
      },
      {
        value: '2',
        stack: [
          {
            title: 'Pflegebedürftige im Haushalt',
            prefix: 'far',
            icon: 'circle',
            class: 'text-muted fa-2x'
          },
          {
            class: 'text-muted',
            title: '2',
          },
        ]
      },
    ]
  },
  ].concat(cRIconBar)

export const careGiverRequirementsIconBar = [
  {
    key: 'ctProfile',
    mappedIcons: [
      {
        value: 'Mann',
        stack: [
          {
            title: 'Mann gewünscht',
            prefix: 'fas',
            icon: 'mars',
            class: 'text-muted  fa-2x',
          }
        ]
      },
      {
        value: 'Frau',
        stack: [
          {
            title: 'Frau gewünscht',
            prefix: 'fas',
            icon: 'venus',
            class: 'text-muted  fa-2x',
          }
        ]
      },
      {
        value: 'egal',
        stack: [
          {
            title: 'Geschlecht egal',
            prefix: 'fas',
            icon: 'transgender',
            class: 'text-muted  fa-2x',
          }
        ]
      },
    ]
  },
  {
    key: 'ctLanguage',
    mappedIcons: [
      {
        value: 'keine',
        stack: [
          {
            title: 'keine DK erforderlich',
            prefix: 'fas',
            icon: 'signal-alt-slash',
            class: 'text-muted',
          }
        ]
      },
      {
        value: 'sehr gering',
        stack: [
          {
            title: 'gewünschte DK: 5',
            prefix: 'fad',
            icon: 'signal-alt',
            class: 'lighter',
          }
        ]
      },
      {
        value: 'ausreichend',
        stack: [
          {
            title: 'gewünschte DK: 4',
            prefix: 'fad',
            icon: 'signal-alt-1',
            class: 'text-muted',
          }
        ]
      },
      {
        value: 'befriedigend',
        stack: [
          {
            title: 'gewünschte DK: 3',
            prefix: 'fad',
            icon: 'signal-alt-2',
            class: 'text-muted',
          }
        ]
      },
      {
        value: 'gut',
        stack: [
          {
            title: 'gewünschte DK: 2',
            prefix: 'fad',
            icon: 'signal-alt-3',
            class: 'text-muted',
          }
        ]
      },
      {
        value: 'sehr gut',
        stack: [
          {
            title: 'gewünschte DK: 1',
            prefix: 'fad',
            icon: 'signal-alt',
            class: 'text-muted',
          }
        ]
      },
    ]
  },
  {
    key: 'ctDriver',
    mappedIcons: [
      {
        value: 'Ja',
        stack: [
          {
            title: 'Führerschein erforderlich',
            icon: 'car-side',
            prefix: 'fas',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'wünschenswert',
        stack: [
          {
            title: 'Führerschein gewünscht',
            prefix: 'fas',
            icon: 'car-side',
            class: 'text-muted'
          }
        ]
      },
      {
        value: 'nicht relevant',
        stack: [
          {
            title: 'Führerschein nicht relevant',
            prefix: 'fas',
            icon: 'car-side',
            class: 'text-muted',
          },
          {
            // title: 'Führerschein',
            prefix: 'far',
            icon: 'ban',
            class: 'text-muted',
          }
        ]
      },
    ]
  },
  {
    key: 'ctSmoker',
    mappedIcons: [
      {
        value: 'Ja',
        stack: [
          {
            title: 'Rauchen gestattet',
            prefix: 'fas',
            icon: 'smoking',
            class: 'text-muted',
          }
        ]
      },
      {
        value: 'nur im Freien',
        stack: [
          {
            title: 'Rauchen nur im Freien',
            prefix: 'fas',
            icon: 'smoking',
            class: 'text-muted',
          }
        ]
      },
      {
        value: 'nicht relevant',
        stack: [
          {
            title: 'Rauchen nicht gestattet',
            prefix: 'fas',
            icon: 'smoking',
            class: 'text-muted',
          },
          {
            title: 'Rauchen nicht gestattet',
            prefix: 'far',
            icon: 'ban',
            class: 'text-muted',
          }
        ]
      },
    ]
  },
]
